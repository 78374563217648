<template>
  <a-modal v-model="visible" :title="isPreview ? 'Просмотр' : 'Редактирование'" :afterClose="close">
    <template slot="footer">
      <a-button key="back" @click="close">
        {{ isPreview ? 'Ок' : 'Отмена'}}
      </a-button>
      <a-button v-if="!isPreview" key="submit" :disabled="isDisabled" type="primary" @click.prevent.stop="submit">
        Сохранить
      </a-button>
    </template>
    <a-form-model ref="form" :model="form">
      <a-form-model-item label="Алиас">
        <a-input :disabled="true" v-model="form.alias"/>
      </a-form-model-item>
      <a-form-model-item label="Позиция" required>
        <a-input v-model.number="form.position" :disabled="isPreview" />
      </a-form-model-item>
      <a-form-model-item label="Проверка" required>
        <a-select v-model="form.required" :disabled="isPreview">
          <a-select-option v-for="type in required" :key="type.id" :value="type.id">
            {{ type.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Название(ru)" required>
        <a-input v-model.number="form.ru.name" :disabled="isPreview" />
      </a-form-model-item>
      <a-form-model-item label="Название(ua)" required>
        <a-input v-model.number="form.ua.name" :disabled="isPreview" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import required from '@/entities/required'

export default {
  name: 'ContactsEditOverviewModal',

  data() {
    return {
      form: {
        alias: '',
        position: '',
        required: 0,
        ru: {
          name: '',
        },
        ua: {
          name: '',
        },
      },
      required,
      id: '',
      visible: false,
      isPreview: false,
    }
  },

  computed: {
    isDisabled() {
      const form = this.form
      return !(`${form.position}` && `${form.required}` && form.ru.name && form.ua.name)
    },
  },

  methods: {
    async open(id, type = '') {
      this.visible = true
      if (type) this.isPreview = true
      this.id = id
      try {
        const { data: { data: { value: form } } } = (await this.$services.get(`admin/contact/setting_contacts/${id}`))

        this.form = {
          alias: form.alias,
          position: form.position,
          required: form.required,
          ru: { name: form.ru.name },
          ua: { name: form.ua.name },
        }
      } catch (e) {
        console.error(e)
      }
    },

    close() {
      this.visible = false
      this.isPreview = false
      this.form = {
        alias: '',
        position: '',
        ru: {
          name: '',
        },
        ua: {
          name: '',
        },
      }
    },

    async submit() {
      try {
        await this.$services.put(`admin/contact/setting_contacts/${this.id}`, this.form)
        this.$emit('getList')
        this.close()
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ant-form {
  ::v-deep .ant-form-item-label {
    height: fit-content;
    line-height: 120%;
    label {
      height: fit-content;
    }
  }
  .ant-input-disabled, .ant-select-disabled {
    background-color: #ffffff;
    color: #595c97;
    ::v-deep .ant-select-selection {
      background-color: #ffffff;
    }
  }
}
</style>
